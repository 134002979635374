"use client";

import { useEffect, useState } from "react";
import { useRouter, usePathname } from "next/navigation";

export default function Layout({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const pathname = usePathname();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    setMounted(true);

    const mediaQuery = window.matchMedia("(min-width: 1492px)");

    const handleMediaChange = (): void => {
      if (!mediaQuery.matches) {
        router.replace("/m");
      } else {
        router.replace(pathname.includes("/m") ? "/" : pathname);
      }
    };

    handleMediaChange();
    mediaQuery.addEventListener("change", handleMediaChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
    };
  }, [router, pathname]);

  if (!mounted) return null;

  return <>{children}</>;
}
